import * as React from 'react'
import { Link } from 'gatsby'
import LogoMarkDark from '../images/logo-mark-teal-500-cyan-600.svg'

const LoginPage = () => (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <LogoMarkDark className="mx-auto h-12 w-auto" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Hello and welcome back!</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Don't yet have an account?{' '}
          <Link to="/join-waitlist" className="font-medium text-cyan-600 hover:text-cyan-500">
            Join the waitlist.
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" action="https://api-mkt.horseofcourseapp.com/login" method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-cyan-600 focus:ring-cyan-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link to="/forgot-password" className="font-medium text-cyan-600 hover:text-cyan-500">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              >
                Sign in
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-3">
              <div>
                <a
                  href="https://api-mkt.horseofcourseapp.com/login?social=facebook"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Facebook</span>
                  <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>

              <div>
                <a
                  href="https://api-mkt.horseofcourseapp.com/login?social=instagram"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Instagram</span>
                  <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10.262 1.667c2.025 0 2.32.01 3.174.05.886.04 1.492.181 2.022.387.557.21 1.06.537 1.477.961.424.416.752.92.96 1.477.207.53.348 1.135.388 2.022.04.89.05 1.173.05 3.436v.067c0 2.202-.01 2.489-.05 3.369-.04.886-.181 1.492-.387 2.022a4.085 4.085 0 0 1-.961 1.477c-.416.424-.92.752-1.477.96-.53.207-1.136.348-2.022.388-.89.04-1.173.05-3.436.05h-.067c-2.202 0-2.489-.01-3.369-.05-.886-.04-1.492-.181-2.022-.387a4.084 4.084 0 0 1-1.477-.961 4.085 4.085 0 0 1-.96-1.477c-.207-.53-.347-1.136-.388-2.022-.04-.854-.05-1.15-.05-3.174v-.525c0-2.025.01-2.32.05-3.173.04-.887.181-1.492.387-2.022.21-.557.537-1.06.961-1.477.417-.424.92-.752 1.477-.96.53-.207 1.136-.348 2.022-.388.854-.04 1.15-.05 3.174-.05h.524Zm-.067 1.501h-.39c-2.047 0-2.32.01-3.172.049-.813.037-1.254.172-1.548.286-.39.152-.667.332-.958.624a2.583 2.583 0 0 0-.624.958c-.114.294-.25.735-.286 1.547-.04.853-.049 1.126-.049 3.173v.39c0 2.047.01 2.32.049 3.172.037.813.172 1.254.286 1.548.152.388.333.667.624.958.291.292.569.472.958.624.294.114.735.25 1.548.286.878.04 1.141.049 3.367.049h.067c2.164 0 2.43-.009 3.3-.049.813-.037 1.254-.172 1.548-.286.388-.152.667-.332.958-.624.292-.291.472-.569.624-.958.114-.294.25-.735.286-1.547.04-.88.049-1.142.049-3.368v-.067c0-2.164-.009-2.43-.049-3.3-.037-.813-.172-1.254-.286-1.548a2.58 2.58 0 0 0-.624-.958 2.582 2.582 0 0 0-.958-.624c-.294-.114-.735-.25-1.547-.286-.853-.04-1.126-.049-3.173-.049ZM10 5.721a4.28 4.28 0 1 1 0 8.558 4.28 4.28 0 0 1 0-8.558Zm0 1.501a2.777 2.777 0 1 0 0 5.555 2.777 2.777 0 0 0 0-5.555Zm4.448-2.67a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"/>
                  </svg>
                </a>
              </div>

              <div>
                <a
                  href="https://api-mkt.horseofcourseapp.com/login?social=phone-number"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with phone number</span>
                  <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)

export default LoginPage
